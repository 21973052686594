/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
/** @jsx jsx */
import React, { useContext, useState } from 'react';
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';
import { StoreContext } from '../context/StoreContext';
import Layout from '../components/Layout';
import SEO from '../components/seo/seo';
import Remove from '../images/remove.inline.svg';

const Cart = () => {
  const {
    checkout,
    removeProductFromCart,
    checkCoupon,
    removeCoupon,
  } = useContext(StoreContext);
  const [coupon, setCoupon] = useState('');

  return (
    <Layout sx={{ fontFamily: 'body' }} customSEO>
      <SEO title="Cart" pathname="/cart" />
      {checkout.lineItems.length > 0 && (
        <Link
          to="/"
          sx={{
            textDecoration: 'none',
            color: 'primary',
            textTransform: 'lowercase',
          }}
        >
          {`<- Continue Shopping`}
        </Link>
      )}
      {checkout.lineItems.length > 0 ? (
        <>
          {checkout.lineItems.map(item => (
            <div
              key={item.id}
              sx={{
                display: 'flex',
                flexWrap: ['wrap', 'nowrap'],
                marginBottom: '2rem',
                marginTop: '1rem',
                fontFamily: 'body',
                alignItems: 'center',
              }}
            >
              {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
              <div
                sx={{
                  width: ['100%', '40%'],
                  // height: ['auto', 300],
                  overflow: 'hidden',
                  marginRight: [0, 50],
                }}
              >
                <Link to={`/product/${item.variant.product.handle}`}>
                  <img
                    src={item.variant.image.src}
                    alt=""
                    sx={{ maxWidth: '100%' }}
                  />
                </Link>
              </div>
              <div>
                <h1
                  sx={{
                    variant: 'styles.subHeader',
                    color: 'text',
                    fontSize: [3, 5],
                    fontWeight: 'normal',
                    marginBottom: [3, 4],
                  }}
                >
                  {item.title}
                </h1>
                {/* TODO: Probably a better way to handle a single variant */}
                {item.variant.title !== 'Default Title' && (
                  <h1
                    sx={{
                      variant: 'styles.subHeader',
                      color: 'text',
                      fontSize: [3, 5],
                      fontWeight: 'normal',
                      marginBottom: [3, 4],
                    }}
                  >
                    {item.variant.title}
                  </h1>
                )}
                <h1
                  sx={{
                    variant: 'styles.subHeader',
                    color: 'text',
                    fontSize: [3, 5],
                    fontWeight: 'normal',
                    marginBottom: [3, 4],
                  }}
                >
                  ${item.variant.price}
                </h1>
                <p
                  sx={{
                    variant: 'styles.subHeader',
                    color: 'text',
                    fontSize: [3, 5],
                    fontWeight: 'normal',
                    marginBottom: [3, 4],
                  }}
                >
                  Qty: {item.quantity}
                </p>
              </div>
              <div
                sx={{
                  flexGrow: 1,
                  display: 'flex',
                  justifyContent: ['center', 'flex-end'],
                }}
              >
                <button
                  onClick={() => removeProductFromCart(item.id)}
                  type="button"
                  sx={{ border: 'none', background: 'transparent' }}
                  title="remove product from cart"
                >
                  <Remove
                    sx={{ stroke: 'text', fill: 'none', strokeWidth: 1 }}
                    title="remove product from cart"
                  />
                </button>
              </div>
            </div>
          ))}
          <div sx={{ backgroundColor: 'text', height: '1px', my: 4 }} />
          <div sx={{ fontFamily: 'body', display: 'flex', fontSize: 3 }}>
            <p
              sx={{
                variant: 'styles.subHeader',
                color: 'text',
                fontSize: [3, 5],
                fontWeight: 'normal',
                marginBottom: [3, 4],
              }}
            >
              <span sx={{ fontWeight: 'bold' }}>Total:</span> $
              {checkout.totalPrice}
            </p>
          </div>
          <div sx={{ fontFamily: 'body', display: 'flex' }}>
            <p>Shipping and taxes are added at checkout.</p>
          </div>
          <div sx={{ marginTop: '2rem' }}>
            <a href={checkout.webUrl} sx={{ textDecoration: 'none' }}>
              <button
                type="button"
                sx={{
                  variant: 'buttons.solid',
                  width: ['100%', '250px'],
                  ':disabled': {
                    opacity: 0.5,
                    cursor: 'not-allowed',
                  },
                }}
              >
                Checkout Now
              </button>
            </a>
          </div>
        </>
      ) : (
        <div
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            flexDirection: 'column',
          }}
        >
          <p
            sx={{
              variant: 'styles.subHeader',
              color: 'text',
              fontSize: [3, 5],
              fontWeight: 'normal',
              mt: [4, 5],
            }}
          >
            You cart is empty
          </p>
          <Link
            to="/"
            sx={{
              textDecoration: 'none',
              color: 'primary',
              textTransform: 'lowercase',
            }}
          >
            Go buy something
          </Link>
        </div>
      )}
    </Layout>
  );
};

export default Cart;
